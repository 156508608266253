'use client';

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Logo from 'components/globals/Logo';

import Link from 'components/ui/Link';
import Text from 'components/ui/Text';

import FooterLinkGroup from './FooterLinkGroup';
import FooterShreds from './FooterShreds';
import FooterSignUp from './FooterSignUp';

import styles from './Footer.module.scss';

const signUpHeadline = 'Subscribe for more deliciousness';
const signUpCopy =
    'Tillamook email keeps you in the loop on all our special releases, discounts, best recipes and more!';
const bCorpInfo = 'Tillamook County Creamery Association IS A CERTIFIED B CORP';

const Footer = ({
    socialMedia,
    footerRouteGroups,
    copyright,
    privacyLink,
    termsLink,
    caSupplyChainsActLink,
}) => {
    const [mobileOpenIndex, setMobileOpenIndex] = useState(-1);

    const handleClick = index => {
        setMobileOpenIndex(index === mobileOpenIndex ? -1 : index);
    };

    return (
        <footer className={styles.root}>
            <section className={styles.signUp}>
                <FooterSignUp
                    headline={signUpHeadline}
                    copy={signUpCopy}
                    socialMedia={socialMedia}
                />
            </section>
            <section className={styles.logoAndLinkGroups}>
                <Link href="/" className={styles.logoLink}>
                    <span className="sr-only">Home Page</span>
                    <Logo withMorningStar className={styles.logo} />
                    <p className={styles.tagline}>
                        Extraordinary
                        <br />
                        Dairy
                    </p>
                </Link>
                <div className={styles.linkGroups}>
                    {footerRouteGroups?.length > 0 &&
                        footerRouteGroups.map((group, i) => {
                            return (
                                <FooterLinkGroup
                                    key={i}
                                    index={i}
                                    headline={group.headline}
                                    routes={group.routesCollection.items}
                                    isMobileOpen={mobileOpenIndex === i}
                                    onClick={handleClick}
                                />
                            );
                        })}
                </div>
            </section>
            <section className={styles.legal}>
                <div className={styles.bCorp}>
                    <img
                        className={styles.bCorpLogo}
                        src="/images/logo-bcorp-cream.svg"
                        alt="B Corporation Logo"
                    />
                    <Text
                        baseTheme="labelSmallAlt"
                        className={styles.bCorpInfo}
                    >
                        {bCorpInfo}
                    </Text>
                </div>
                <div className={styles.copyrightAndLegal}>
                    <Text
                        as="div"
                        className={styles.copyrightWithLegalLinks}
                        baseTheme="bodyXSmall"
                    >
                        <div className={styles.copyright}>{copyright}</div>
                        <div className={styles.legalLinks}>
                            {privacyLink && (
                                <Link
                                    href={privacyLink?.link?.href}
                                    as={`/${privacyLink?.link?.as}`}
                                    className={styles.legalLink}
                                >
                                    {privacyLink?.text}
                                </Link>
                            )}
                            {termsLink && (
                                <Link
                                    href={termsLink?.link?.href}
                                    as={`/${termsLink?.link?.as}`}
                                    className={styles.legalLink}
                                >
                                    {termsLink?.text}
                                </Link>
                            )}
                            {caSupplyChainsActLink && (
                                <Link
                                    href={caSupplyChainsActLink?.link?.href}
                                    as={`/${caSupplyChainsActLink?.link?.as}`}
                                    className={styles.legalLink}
                                >
                                    {caSupplyChainsActLink?.text}
                                </Link>
                            )}
                        </div>
                    </Text>
                    <Text
                        as="p"
                        id="rBST"
                        baseTheme="bodyXSmall"
                        className={styles.disclaimer}
                    >
                        *All farmers who supply milk for Tillamook products
                        pledge to not use artificial growth hormones. The FDA
                        has stated that no significant difference has been shown
                        between milk derived from rBST treated and non-rBST
                        treated cows.
                    </Text>
                </div>
            </section>
            <FooterShreds mobileOpenIndex={mobileOpenIndex} />
        </footer>
    );
};

Footer.propTypes = {
    socialMedia: PropTypes.array,
    footerRouteGroups: PropTypes.array.isRequired,
    copyright: PropTypes.string.isRequired,
    privacyLink: PropTypes.object.isRequired,
    termsLink: PropTypes.object.isRequired,
    caSupplyChainsActLink: PropTypes.object.isRequired,
};

export default Footer;
